import { Autocomplete, Chip, TextField } from "@mui/material";
import React, { Component, Fragment } from "react";
import { InputGroup } from "react-bootstrap";
import { EyeSlash } from "react-bootstrap-icons";
import makeAnimated from "react-select/animated";
// import { isDate, shortDate } from "./date";

class SearchOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      selectedOptions: [],
      vals: this.props.vals,
      selectedFilters: this.props.selectedFilters,
      seed: -1,
      // selectedFields: this.props.selectedFields,
    };
  }

  animatedComponents = makeAnimated();

  customStyles = {
    option: (provided) => ({
      ...provided,
    }),
  };

  componentDidMount() {
    let options = [];

    this.props.fields.forEach((field) => {
      options.push({
        value: field.EntityMetaType_DBID,
        label: field.EntityMetaType,
      });
    });

    // Remove fields saved in cookie from dropdown options
    options = options.filter(
      (o) => !this.props.selectedFields.some((x) => x.value === o.value)
    );

    this.setState({
      options: options,
      rows: this.props.rows,
      vals: this.props.vals,
      selectedFilters: this.props.selectedFilters,
      // selectedFields: this.props.selectedFields,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        rows: this.props.rows,
        vals: this.props.vals,
        selectedFilters: this.props.selectedFilters,
      });
    }
  }

  // Called when a new filter/column is added
  handleChange = (newValue) => {
    newValue.id = newValue.id ? newValue.id : newValue.label;

    let opts = this.props.selectedFields.concat(newValue);

    let filters = this.state.selectedFilters.filter((f) => {
      return opts.find((o) => {
        return f.key === o.label || f.field === o.label || f.key === o.id;
      });
    });

    opts = opts.filter(
      (v, i, a) => a.findIndex((v2) => v2.label === v.label) === i
    );

    // Remove all selected fields from dropdown options
    this.setState({
      // selectedFields: opts,
      options: this.state.options.filter(
        (o) => !newValue.some((n) => o.label === n.label)
      ),
    });

    this.props.setColumns(opts, filters);
  };

  // Called when a filter/column is removed
  removeField = (field) => {
    let opts = this.props.selectedFields.filter((o) => o.label !== field.label);

    let filters = this.state.selectedFilters.filter((f) => {
      return opts.find((o) => {
        return f.key === o.label || f.field === o.label || f.key === o.id;
      });
    });

    let newOpts = this.state.options
      .concat(field)
      .sort((a, b) => a.label.localeCompare(b.label))
      .filter((v, i, a) => a.findIndex((v2) => v2.label === v.label) === i);

    this.setState({
      selectedFilters: filters,
      // selectedFields: opts,
      // selectedOptions: opts,
      options: newOpts,
    });

    this.props.setColumns(opts);
    this.props.setFilters(filters, {});
  };

  // Called when a column filter value is changed
  handleFieldChange = (selectedOptions, field) => {
    let filters = this.state.selectedFilters ?? [];
    filters = filters.filter((x) => x.key !== field);
    selectedOptions.forEach((o) => {
      filters.push(o);
    });
    this.setState({ selectedFilters: filters });
    this.props.setFilters(filters, {});
  };

  render() {
    return (
      <div
        className="d-flex advanced-filters justify-content-start pb-1 pt-2 mt-2"
        style={{ width: "100%", zIndex: 901 }}
      >
        {this.props.selectedFields
          .filter((x) => x.id !== "Menu")
          .map((x, i) => {
            let id = x.id ? x.id : x.label;
            let options =
              this.props.vals.filter((f) => id === f.name)[0]?.vals ?? [];

            let filteredOptions = options.map((o) => ({
              key: id,
              field: id,
              label: o,
              value: o,
            }));
            filteredOptions = filteredOptions?.filter(
              (ar) =>
                !this.state.selectedFilters.find(
                  (rm) => rm.key === ar.key && ar.label === rm.label
                )
            );
            // options.push("(Blank)");
            return id !== "EntityName" ? (
              <Fragment key={i}>
                <InputGroup className="flex-nowrap">
                  {/* Individual filter controls */}
                  <Autocomplete
                    autoHighlight={true}
                    size="small"
                    multiple
                    filterSelectedOptions
                    limitTags={1}
                    id="field-filter"
                    value={this.state.selectedFilters.filter(
                      (f) => f.key === (x.id ?? x.label)
                    )}
                    onChange={(event, newValue) => {
                      this.handleFieldChange(newValue, id);
                    }}
                    options={filteredOptions}
                    isOptionEqualToValue={(option) => {
                      return this.props.selectedFields.filter(
                        (f) => f.label === option.label
                      ).length;
                    }}
                    getOptionLabel={(option) => option.label}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          size="small"
                          key={index}
                          label={option.label}
                          {...getTagProps({ index })}
                          disabled={option.isFixed}
                        />
                      ))
                    }
                    style={{ width: "200px" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: "200px" }}
                        label={x.label}
                      />
                    )}
                  />

                  {/* Remove column button  */}
                  {x.isFixed ? null : (
                    <InputGroup.Text>
                      <EyeSlash
                        className="pointer filter-trash-icon"
                        color="red"
                        onClick={() => this.removeField(x)}
                      />
                    </InputGroup.Text>
                  )}
                </InputGroup>
              </Fragment>
            ) : null;
          })}

        {/* Add new column/filter control */}
        <Autocomplete
          autoHighlight={true}
          size="small"
          disableClearable
          multiple
          filterSelectedOptions
          id="fields-filter"
          onChange={(event, newValue) => {
            this.handleChange(newValue);
          }}
          // value={this.state.selectedOptions.filter((o) => !o.isFixed)}
          value={this.props.selectedFields.filter((o) => !o.isFixed)}
          options={this.state.options}
          getOptionLabel={(option) => option.label}
          renderTags={() => null}
          style={{ width: 1000 }}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{
                width: "200px",
                backgroundColor: "#d4f8ff",
              }}
              label="New Filter"
            />
          )}
        />
      </div>
    );
  }
}

export default React.memo(SearchOptions);
